// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-5-years-after-jsx": () => import("./../../../src/pages/5-years-after.jsx" /* webpackChunkName: "component---src-pages-5-years-after-jsx" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-archive-js": () => import("./../../../src/pages/archive.js" /* webpackChunkName: "component---src-pages-archive-js" */),
  "component---src-pages-asthma-js": () => import("./../../../src/pages/asthma.js" /* webpackChunkName: "component---src-pages-asthma-js" */),
  "component---src-pages-demo-js": () => import("./../../../src/pages/demo.js" /* webpackChunkName: "component---src-pages-demo-js" */),
  "component---src-pages-democracy-js": () => import("./../../../src/pages/democracy.js" /* webpackChunkName: "component---src-pages-democracy-js" */),
  "component---src-pages-environmentalracism-js": () => import("./../../../src/pages/environmentalracism.js" /* webpackChunkName: "component---src-pages-environmentalracism-js" */),
  "component---src-pages-hb-1820-js": () => import("./../../../src/pages/hb1820.js" /* webpackChunkName: "component---src-pages-hb-1820-js" */),
  "component---src-pages-houston-arrow-1-up-and-down-on-richmond-js": () => import("./../../../src/pages/houston-arrow/1-up_and_down_on_richmond.js" /* webpackChunkName: "component---src-pages-houston-arrow-1-up-and-down-on-richmond-js" */),
  "component---src-pages-houston-arrow-2-a-home-for-the-ruling-class-js": () => import("./../../../src/pages/houston-arrow/2-a_home_for_the_ruling_class.js" /* webpackChunkName: "component---src-pages-houston-arrow-2-a-home-for-the-ruling-class-js" */),
  "component---src-pages-houston-arrow-3-from-porches-to-townhomes-js": () => import("./../../../src/pages/houston-arrow/3-from_porches_to_townhomes.js" /* webpackChunkName: "component---src-pages-houston-arrow-3-from-porches-to-townhomes-js" */),
  "component---src-pages-houston-arrow-4-moving-beyond-maps-js": () => import("./../../../src/pages/houston-arrow/4_moving-beyond-maps.js" /* webpackChunkName: "component---src-pages-houston-arrow-4-moving-beyond-maps-js" */),
  "component---src-pages-houston-arrow-js": () => import("./../../../src/pages/houston-arrow.js" /* webpackChunkName: "component---src-pages-houston-arrow-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-index-old-js": () => import("./../../../src/pages/index-old.js" /* webpackChunkName: "component---src-pages-index-old-js" */),
  "component---src-pages-mountains-of-houston-js": () => import("./../../../src/pages/mountains-of-houston.js" /* webpackChunkName: "component---src-pages-mountains-of-houston-js" */),
  "component---src-pages-mountains-of-houston-old-redirect-jsx": () => import("./../../../src/pages/mountains-of-houston-old-redirect.jsx" /* webpackChunkName: "component---src-pages-mountains-of-houston-old-redirect-jsx" */),
  "component---src-pages-newsroom-js": () => import("./../../../src/pages/newsroom.js" /* webpackChunkName: "component---src-pages-newsroom-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-ruling-img-js": () => import("./../../../src/pages/RulingImg.js" /* webpackChunkName: "component---src-pages-ruling-img-js" */),
  "component---src-pages-scrollmap-js": () => import("./../../../src/pages/scrollmap.js" /* webpackChunkName: "component---src-pages-scrollmap-js" */),
  "component---src-pages-subscribe-js": () => import("./../../../src/pages/subscribe.js" /* webpackChunkName: "component---src-pages-subscribe-js" */),
  "component---src-pages-take-action-itc-deer-park-js": () => import("./../../../src/pages/take-action/itc-deer-park.js" /* webpackChunkName: "component---src-pages-take-action-itc-deer-park-js" */),
  "component---src-pages-take-action-js": () => import("./../../../src/pages/take-action.js" /* webpackChunkName: "component---src-pages-take-action-js" */),
  "component---src-pages-take-action-tceq-pleasantville-js": () => import("./../../../src/pages/take-action/tceq-pleasantville.js" /* webpackChunkName: "component---src-pages-take-action-tceq-pleasantville-js" */),
  "component---src-templates-author-page-js": () => import("./../../../src/templates/author-page.js" /* webpackChunkName: "component---src-templates-author-page-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-cat-page-js": () => import("./../../../src/templates/cat-page.js" /* webpackChunkName: "component---src-templates-cat-page-js" */)
}

